<template>
  <div class=container>
      <div class=table>
<p>
</p><h3> Spheres rendered using estimated radiance as predicted by the 55 parameter  
Koenderink at al. representation.  </h3>
<p>
For each of the 61 samples measured, a frontally viewed sphere
is rendered with the illumination taken at
22.5 degrees to the right of the viewing direction.
The image below shows each of the resulting 61 spheres.
</p>
<img src="https://cave.cs.columbia.edu/old/software/curet/gif/figkoen8.jpg">
<hr>
<router-link to="/repository/CURET/brdfp"> [Return to BRDF Parameter Section] </router-link>


      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>